.c-overlay-gradient--grey {
  height: 126px;
  width: 388px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 100%);
  position: absolute;
  bottom: 0px;
}

.c-overlay--dark {
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0.5;
}

.c-overlay--grey {
  width: 100%;
  height: 100%;
  border: inherit;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: map_get($color-primary-black, dark);
  opacity: 0.7;
}

.c-overlay,
.u-unclickable {
  width: 100%;
  height: 100%;
  z-index: 3;
  border: inherit;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}
