.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url('spritesheets/spritesheet-e5d05818.svg');
  vertical-align: middle;
  flex-shrink: 0; // Assures that icons will not be cut off in flex containers
}

.icon--empty {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: -48px 0;
}
.icon-Assess {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-position: -68px -40px;
}
.icon-Calculate {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-position: 0 -68px;
}
.icon-Edit {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -17px -68px;
}
.icon-Home {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-position: -33px -68px;
}
.icon-Intercom---white {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: -48px -20px;
}
.icon-Lifeline-airport {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -84px -85px;
}
.icon-Lifeline-airport-down {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -50px -68px;
}
.icon-Lifeline-bridge {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -104px 0;
}
.icon-Lifeline-bridge-down {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -66px -68px;
}
.icon-Lifeline-highway {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -104px -16px;
}
.icon-Lifeline-highway-down {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -88px 0;
}
.icon-Lifeline-people {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -104px -32px;
}
.icon-Lifeline-people-down {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-position: 0 -85px;
}
.icon-Lifeline-port {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -104px -48px;
}
.icon-Lifeline-port-down {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -88px -17px;
}
.icon-Lifeline-power {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -104px -64px;
}
.icon-Lifeline-power-down {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -88px -34px;
}
.icon-Lifeline-repair-time {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: 0 -48px;
}
.icon-Lifeline-structure {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -104px -80px;
}
.icon-Lifeline-structure-down {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -88px -51px;
}
.icon-MapPin {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -88px -68px;
}
.icon-User {
  @extend .icon;
  width: 16px;
  height: 17px;
  background-position: -17px -85px;
}
.icon-Users {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-position: -33px -85px;
}
.icon-close---white {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: 0 -102px;
}
.icon-document---orange {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -16px -102px;
}
.icon-earthquake {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: -20px -48px;
}
.icon-earthquake---24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-position: 0 0;
}
.icon-emphasis2---orange {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -32px -102px;
}
.icon-emphasis2---red {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -48px -102px;
}
.icon-emphasis2---white {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -64px -102px;
}
.icon-flood {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: -40px -48px;
}
.icon-flood---24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-position: -24px 0;
}
.icon-hurricane {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: -68px 0;
}
.icon-hurricane---24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-position: 0 -24px;
}
.icon-info2---blue {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -80px -102px;
}
.icon-info2---orange {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -96px -102px;
}
.icon-info2---white {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -120px 0;
}
.icon-search16---dark {
  @extend .icon;
  width: 17px;
  height: 16px;
  background-position: -50px -85px;
}
.icon-search16---white {
  @extend .icon;
  width: 17px;
  height: 16px;
  background-position: -67px -85px;
}
.icon-success2---green {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -120px -16px;
}
.icon-success2---white {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-position: -120px -32px;
}
.icon-wind {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-position: -68px -20px;
}
.icon-wind---24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-position: -24px -24px;
}
