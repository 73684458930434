.u-background-black {
  background-color: $color-black !important;
}

@each $name, $color in $color-primary-black {
  .u-background-black--#{$name} {
    background-color: $color !important;
  }
}

@each $name, $color in $color-primary-blue {
  .u-background-blue--#{$name} {
    background-color: $color !important;
  }
}

.u-background-white {
  background-color: $color-white !important;
}

.u-background-red {
  background-color: $color-red !important;
}

.u-background-red-light {
  background-color: rgba($color-red, 0.1) !important;
}

.u-background-green {
  background-color: $color-green !important;
}

.u-background-green-light {
  background-color: rgba($color-green, 0.1) !important;
}

.u-background-orange {
  background-color: $color-orange !important;
}

.u-background-grey {
  background-color: $color-gs40 !important;
}

.u-background-grey-dark {
  background-color: $color-grey !important;
}

.u-background-grey-light {
  background-color: $color-gs05 !important;
}

.u-background-yellow {
  background-color: $color-yellow !important;
}

.u-background-yellow-light {
  background-color: rgba($color-yellow, 0.1) !important;
}

.u-background-lightwhite {
  background-color: $color-gs01 !important;
}

// .u-border-bottom-red {
//   border-bottom-color: map_get($color-stats-bdi,bdi3);
// }
// .u-border-bottom-yellow {
//   border-bottom-color: map_get($color-stats-bdi,bdi2);
// }
// .u-border-bottom-green {
//   border-bottom-color: map_get($color-stats-bdi,bdi1);
// }
// .u-border-bottom-blue {
//   border-bottom-color: map_get($color-stats-bdi,bdi0);
// }
.u-red {
  color: $color-red !important;
}
