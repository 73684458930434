//Import order is important
@import 'modules/animations.scss';
@import 'modules/color_definitions.scss';
@import 'modules/colors.scss';
@import 'modules/containers.scss';
@import 'modules/fonts.scss';
@import 'modules/overlays.scss';
@import 'modules/shapes.scss';
@import 'layout/utils.scss';

@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'mapboxgl-overrides.scss';
@import 'icons.scss';

html,
body,
#root,
main {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #283238; // DARKBLUE in productGlobals.ts
}

input {
  border: none;
  font: none;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: $dark-blue2;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
