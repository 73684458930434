/* noto-sans-jp-regular  */
/* This is a really large font. WOFF is 1.9 MB and WOFF2 is 1.7 MB */
/* I'm only including WOFF2 because every modern browser supports it and it's smaller */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans JP Regular'), local('NotoSansJP-Regular'),
    local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'),
    local('Noto Sans CJK JP'),
    url('../fonts/noto-sans-jp-v25-japanese-regular.woff2') format('woff2');
  unicode-range: U+3000-303F, U+3040-309F, U+30A0-30FF, U+FF00-FFEF, U+4E00-9FAF;
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Japanese Bold'), local('NotoSansJapanese-Bold'),
    url('../fonts/noto-sans-jp-v25-japanese-700.woff2') format('woff2');
  unicode-range: U+3000-303F, U+3040-309F, U+30A0-30FF, U+FF00-FFEF, U+4E00-9FAF;
}

/* m-plus-1p-300 - latin */
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 300;
  src: local('M PLUS 1p Light'), url('../fonts/m-plus-1p-v19-latin-300.woff2') format('woff2'),
    url('../fonts/m-plus-1p-v19-latin-300.woff') format('woff');
  unicode-range: U+000-5FF;
}

/* m-plus-1p-regular - latin */
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 400;
  src: local('M PLUS 1p Regular'), url('../fonts/m-plus-1p-v19-latin-regular.woff2') format('woff2'),
    url('../fonts/m-plus-1p-v19-latin-regular.woff') format('woff');
  unicode-range: U+000-5FF;
}

/* m-plus-1p-500 - latin */
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 500;
  src: local('M PLUS 1p Light Semibold'),
    url('../fonts/m-plus-1p-v19-latin-500.woff2') format('woff2'),
    url('../fonts/m-plus-1p-v19-latin-500.woff') format('woff');
  unicode-range: U+000-5FF;
}

/* m-plus-1p-700 - latin */
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 700;
  src: local('M PLUS 1p Bold'), url('../fonts/m-plus-1p-v19-latin-700.woff2') format('woff2'),
    url('../fonts/m-plus-1p-v19-latin-700.woff') format('woff');
  unicode-range: U+000-5FF;
}
/* m-plus-1p-800 - latin */
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 800;
  src: local('M PLUS 1p Bolder'), url('../fonts/m-plus-1p-v19-latin-800.woff2') format('woff2'),
    url('../fonts/m-plus-1p-v19-latin-800.woff') format('woff');
  unicode-range: U+000-5FF;
}

/* m-plus-1p-900 - latin */
@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 900;
  src: local('M PLUS 1p Extreme Bold'),
    url('../fonts/m-plus-1p-v19-latin-900.woff2') format('woff2'),
    url('../fonts/m-plus-1p-v19-latin-900.woff') format('woff');
  unicode-range: U+000-5FF;
}

/* Both fonts are included so Japanese characters all use Noto Sans JP and Latin
   characters all use M PLUS 1p */
$mixed-font-family: 'Noto Sans JP', 'M PLUS 1p', sans-serif;

.f-font {
  font-family: $mixed-font-family !important;
}

.f-body,
body {
  font-family: $mixed-font-family;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.f-base,
p {
  font-family: inherit;
  font-size: 12px;
  text-align: left;
  line-height: 17px;
}

.f-heading01,
h1 {
  font-family: inherit;
  font-size: 32px;
  font-weight: bold;
  line-height: 46px;
  text-align: left;
}

.f-heading02,
h2 {
  font-family: inherit;
  font-size: 26px;
  font-weight: bold;
  line-height: 37px;
  text-align: left;
}

.f-heading03,
h3 {
  font-family: inherit;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  text-align: left;
}

.f-heading04,
h4 {
  font-family: inherit;
  font-size: 16px;
  line-height: 23px;
  text-align: left;
}

.f-title,
title,
h5 {
  font-family: inherit;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
}

.f-caption,
.f-tiny {
  font-family: inherit;
  font-size: 10px;
  line-height: 14px;
  text-align: left;
}

.f-button,
button {
  font-family: $mixed-font-family !important;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
}

.f-floating-label {
  font-family: inherit;
  font-size: 10px !important;
  line-height: 10px !important;
}

.f-form-field-text {
  font-size: 14px;
  line-height: 40px;
}

.f-bold {
  font-weight: bold;
}

.f-uppercase {
  text-transform: uppercase;
}

.f-blue {
  color: $color-blue;
}

.f-grey {
  color: $color-grey;
}

.f-darkgrey {
  color: $color-darkgrey;
}

.f-white {
  color: $color-white;
}

.f-black {
  color: $color-black;
}

.f-red {
  color: $color-red;
}

.f-green {
  color: $color-green;
}

.f-lightgrey {
  color: $color-gs40;
}

.f-yellow {
  color: $color-yellow;
}

.f-justify {
  text-align: justify;
}

.f-black2 {
  color: $color-black2;
}

.f-lightgrey2 {
  color: $color-gs01 !important;
}

%f-button {
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
