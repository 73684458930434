$default-transition-duration: 0.4s;

// Margin Utils
// All
$margin-all: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
);
@each $margin_name, $margin_value in $margin-all {
  .u-margin--#{$margin_name} {
    margin: $margin_value !important;
  }
}

//x
$margin-x: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
);
@each $margin_name, $margin_value in $margin-x {
  .u-marginx--#{$margin_name} {
    margin-left: $margin_value !important;
    margin-right: $margin_value !important;
  }
}

// y
$margin-y: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
);
@each $margin_name, $margin_value in $margin-y {
  .u-marginy--#{$margin_name} {
    margin-top: $margin_value !important;
    margin-bottom: $margin_value !important;
  }
}

// left
$margin-left: (
  '0': 0px,
  '4': 4px,
  '6': 6px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '24': 24px,
  '25': 25px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '50': 50px,
  '60': 60px,
);
@each $margin_name, $margin_value in $margin-left {
  .u-marginleft--#{$margin_name} {
    margin-left: $margin_value !important;
  }
}

// right
$margin-right: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '20': 20px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '50': 50px,
  '60': 60px,
);
@each $margin_name, $margin_value in $margin-right {
  .u-marginright--#{$margin_name} {
    margin-right: $margin_value !important;
  }
}

// top
$margin-top: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '50': 50px,
  '60': 60px,
  '100': 100px,
  '150': 150px,
);
@each $margin_name, $margin_value in $margin-top {
  .u-margintop--#{$margin_name} {
    margin-top: $margin_value !important;
  }
}

// bottom
$margin-bottom: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '12': 12px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '50': 50px,
  '60': 60px,
  '100': 100px,
);
@each $margin_name, $margin_value in $margin-bottom {
  .u-marginbottom--#{$margin_name} {
    margin-bottom: $margin_value !important;
  }
}

// Padding
.u-no-padding {
  padding: 0 !important;
}

$padding: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '2': 2px,
  '5': 5px,
  '10': 10px,
  '20': 20px,
);

@each $padding_name, $padding_value in $padding {
  .u-padding--#{$padding_name} {
    padding: $padding_value !important;
  }
}

// x
$padding-x: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '25': 25px,
  '30': 30px,
  '64': 64px,
);
@each $padding_name, $padding_value in $padding-x {
  .u-paddingx--#{$padding_name} {
    padding-left: $padding_value !important;
    padding-right: $padding_value !important;
  }
}
// y

$padding-y: (
  '0': 0px,
  '4': 4px,
  '6': 6px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '2': 2px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '30': 30px,
  '40': 40px,
  '120': 120px,
);
@each $padding_name, $padding_value in $padding-y {
  .u-paddingy--#{$padding_name} {
    padding-top: $padding_value !important;
    padding-bottom: $padding_value !important;
  }
}
// top
$padding-top: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '3': 3px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '24': 24px,
  '30': 30px,
  '40': 40px,
);
@each $padding_name, $padding_value in $padding-top {
  .u-paddingtop--#{$padding_name} {
    padding-top: $padding_value !important;
  }
}

// left
$padding-left: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '30': 30px,
);
@each $padding_name, $padding_value in $padding-left {
  .u-paddingleft--#{$padding_name} {
    padding-left: $padding_value !important;
  }
}

// right
$padding-right: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '10': 10px,
  '20': 20px,
  '35': 35px,
);
@each $padding_name, $padding_value in $padding-right {
  .u-paddingright--#{$padding_name} {
    padding-right: $padding_value !important;
  }
}

// bottom
$padding-bottom: (
  '0': 0px,
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '32': 32px,
  '5': 5px,
  '10': 10px,
  '12': 12px,
  '15': 15px,
  '20': 20px,
  '24': 24px,
  '30': 30px,
  '40': 40px,
);
@each $padding_name, $padding_value in $padding-bottom {
  .u-paddingbottom--#{$padding_name} {
    padding-bottom: $padding_value !important;
  }
}

// widths
$width: (
  '25': 25%,
  '33': 33%,
  '50': 50%,
  '100': 100%,
);
@each $name, $value in $width {
  .u-width--#{$name} {
    width: $value !important;
  }
}

//height
.u-height--100 {
  height: 100% !important;
}

$min-height: (
  '8': 8px,
  '16': 16px,
  '24': 24px,
  '32': 32px,
);
@each $minHeight_name, $minHeight_value in $min-height {
  .u-minHeight--#{$minHeight_name} {
    min-height: $minHeight_value !important;
  }
}

// alignments
.u-aligncenter {
  text-align: center !important;
}

.u-textalign-center {
  text-align: center !important;
}

.u-textalign-right {
  text-align: right !important;
}

.u-inline-block {
  display: inline-block !important;
}

// flex
.u-flex {
  display: flex !important;
}

.u-flex-column {
  flex-direction: column !important;
}

.u-flex-wrap {
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  flex-wrap: wrap;
}

.u-flex-growshrink {
  flex: 1 1 auto !important;
}

.u-flex-stretch {
  align-items: stretch !important;
}

.u-flex-alignstart {
  align-items: flex-start !important;
}

.u-flex-alignend {
  align-items: flex-end !important;
}

.u-flex-noalign {
  align-items: initial;
}

.u-flexgrow {
  flex-grow: 1 !important;
}

.u-flex-fixcol {
  flex: none;
  align-self: flex-start;
}

.u-flex-fixoverflow {
  min-width: 0;
}

.u-flex-noshrink {
  flex-shrink: 0 !important;
}

.u-flexgrow-noshrink {
  flex: 1 0 !important;
}

.u-flexshrink-nogrow {
  flex: 0 1 !important;
}

.u-flex-fixed {
  flex: 0 0 auto !important;
}

.u-flex-basis--auto {
  flex-basis: auto !important;
}

.u-float-right {
  float: right !important;
}

.u-float-left {
  float: left !important;
}

.u-alignself-end {
  align-self: flex-end;
}

.u-alignself-start {
  align-self: flex-start;
}

.u-hide {
  opacity: 0 !important;
  transition: opacity 0.5s;
}

.u-underlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

// position
.u-position--abs {
  position: absolute !important;
}

.u-position--rel {
  position: relative !important;
}

.u-center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
}

.u-vcenter-absolute {
  position: absolute;
  left: 50%;
  div {
    // Making sure that it fits perfectly in the center
    position: relative;
    left: -50%;
  }
}

// z-index
$z-index: 6;
@for $i from 1 through $z-index {
  .u-zindex--#{$i} {
    z-index: $i;
  }
}

// overflow utilities
.u-overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-wrap {
  word-wrap: break-word;
}

.u-visibility--hidden {
  visibility: hidden !important;
}

.u-visibility--show {
  visibility: visible !important;
}

.u-visually-hidden {
  @extend .u-position--abs;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
}

.u-preline {
  white-space: pre-line !important;
}

.u-wordwrap {
  white-space: pre-wrap !important;
}

.u-draggable {
  cursor: move;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  > :not(.u-draggable) {
    cursor: auto;
  }
}

.u-clickable {
  cursor: pointer !important;
  -webkit-transition-duration: $default-transition-duration;
  -moz-transition-duration: $default-transition-duration;
  transition-duration: $default-transition-duration;
}

.u-overflowy-auto {
  overflow-y: hidden !important;
  &:hover {
    overflow-y: auto !important;
  }
}

// We should get rid of this utility classes because Firefox does not support 'overlay'
.u-overflowy-overlay {
  overflow-y: hidden !important;
  &:hover {
    overflow-y: overlay !important;
    overflow-x: hidden;
  }
}

.u-text-transform-unset {
  text-transform: unset;
}

// border radius
$border-radius: (
  '0': 0,
  '3': 3px,
  '5': 5px,
  '6': 6px,
  '10': 10px,
  '20': 20px,
  '46': 46px,
  '100': 100px,
);
@each $radius_name, $radius_value in $border-radius {
  .u-radius--#{$radius_name} {
    border-radius: $radius_value !important;
  }
}

.u-boxshadow {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.u-no-opacity {
  opacity: 0 !important;
}

.u-no-transition {
  transition: none !important;
}

.u-link {
  color: #f29220 !important;
  font-weight: 400;
}

.u-link:hover {
  text-decoration: underline !important;
}

.u-full-width {
  width: 100%;
}
