@mixin transition($args...) {
  /* Example Usage: @include transition(all .3s ease .5s); */
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin addTransition($transition) {
  -moz-transition-duration: $transition;
  -webkit-transition-duration: $transition;
  -o-transition-duration: $transition;
  transition-duration: $transition;
}

@mixin addTimingFunction($timing_function) {
  -moz-transition-timing-function: $timing_function;
  -webkit-transition-timing-function: $timing_function;
  -o-transition-timing-function: $timing_function;
  transition-timing-function: $timing_function;
}

.hide {
  overflow-y: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  @include addTransition(0.3s);
  @include addTimingFunction(ease-out);
}

.show {
  @include addTransition(0.3s);
  @include addTimingFunction(ease-in);
}

.grow {
  @include addTransition(1s);
  @include addTimingFunction(ease-in);
  transform: scale(1, 1);
  -ms-transform: scale(1, 1); /* IE 9 */
  -webkit-transform: scale(1, 1); /* Safari and Chrome */
  -o-transform: scale(1, 1); /* Opera */
  -moz-transform: scale(1, 1); /* Firefox */
}

.js-hide-form {
  // No animations on hiding form
  overflow-y: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.js-show-form {
  @extend .show;
  overflow-y: hidden;
  max-height: 1000px;
}

.slide-in {
  animation: slide-in 0.1s forwards;
  -webkit-animation: slide-in 0.1s forwards;
}

.slide-out {
  animation: slide-out 0.1s forwards;
  -webkit-animation: slide-out 0.1s forwards;
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

.fade-in-up {
  animation-name: fade-in-up;
  animation-duration: 400ms;
  animation-fill-mode: both;
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  40% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-out-down {
  animation-name: fade-out-down;
  animation-duration: 600ms;
  animation-fill-mode: both;
}

@keyframes fade-out-down {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
