/*******************************************************/
/********************* CONTAINERS **********************/
/*******************************************************/
/* container for all the body content below navbar */
.o-bodycontent-container {
  padding: 5px;
  display: flex;
  height: calc(100vh - 46px);
}

.o-flex-container {
  display: flex;
}

.o-flex-center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.o-flex-vcenter-container {
  display: flex;
  align-items: center;
}

.o-flex-hcenter-container {
  display: flex;
  justify-content: center;
}

.o-flexsb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.o-flexsa-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.o-flexse-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.o-flexfs-container {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.o-flexfe-container {
  display: flex;
  justify-content: flex-end;
  align-items: end;
}

.o-flexcol-container {
  display: flex;
  flex-direction: column;
}

.o-flexrow-container {
  display: flex;
  flex-direction: row;
}

.o-flexstretch-container {
  display: flex;
  align-items: stretch;
}

.o-block-container {
  display: block;
}

// Provides automated spacing between cards
.o-card-container {
  &.o-flex-container,
  &.o-flexrow-container {
    & > .c-card + .c-card,
    > div + div {
      @extend .u-marginleft--16;
    }
  }
  &.o-flexcol-container {
    & > .c-card + .c-card,
    > div + div {
      @extend .u-margintop--16;
    }
  }
}
