/* shapes */
.u-pill {
  padding: 5px 20px;
  border-radius: 22px;
  text-align: center;
  @extend .u-clickable;
}

.u-pill--small {
  padding: 2px 10px;
  border-radius: 20px;
  text-align: left;
  width: 95%;
}

.c-colorbox {
  width: 30px;
  height: 12px;
  border-radius: 3px;
  opacity: 0.7;
}

.u-vertical-partition {
  border-left: 1px solid map_get($color-primary-black, lighter);
}

.u-vertical-partition--light {
  border-left: 1px solid map_get($color-primary-black, light);
}

.u-vertical-partition--dark {
  border-left: 1px solid map_get($color-primary-black, dark);
}

.u-vertical-partition--red {
  border-left: 1px solid $color-red;
}

.u-horizontal-partition {
  border-top: 3px solid map_get($color-primary-black, lighter);
  width: 100%;
}

.u-horizontal-partition--thin {
  border-top: 1px solid map_get($color-primary-black, lighter);
  width: 100%;
}

.u-horizontal-partition-light--thin {
  border-top: 1px solid map_get($color-primary-black, light);
}

.u-horizontal-partition--thin-dark {
  border-top: 1px solid map_get($color-primary-black, dark);
  width: 100%;
}
